import _ from 'lodash';
import { axios } from './index';

import {
  Config,
  ConfigPayload,
  CreateLabelPayload,
  CreateTagPayload,
  GetTagPayload,
  Project,
  SequenceData,
  SequenceFasta,
  SequenceFilter,
  SequenceGroup,
  SequenceGroupData,
  SequenceInfo,
  SequencesFasta,
  SequenceSource,
  SequenceTag,
  SequenceTree,
  Structure,
  Tag,
  UpdateTagSequencePayload,
  Paper,
  Location,
  Sequence,
  Parents,
  SequenceColumn,
  Ligand,
  LigandDocking,
  LigandDockingStatus,
  SequenceGroupProgress,
} from 'types';
// Helpers
import { queryParams } from './helpers';
import { Cluster } from 'cluster';

/* Project Config */
const GET_PROJECT_CONFIG = (projectId: string) => `/projects/${projectId}/config`;
/* Sequence group */
const SEQUENCE_GROUP_URL__DEPRECATED = '/sequence-group';
const SEQUENCE_GROUPS_URL = (projectId: string) => `/projects/${projectId}/groups`;
const SEQUENCE_GROUPS_FASTA_URL = (projectId: string) => `/projects/${projectId}/groups/fasta`;
const SEQUENCE_GROUP_URL = (projectId: string, groupId: number) => `/projects/${projectId}/groups/${groupId}`;
const SEQUENCE_GROUP_SOURCE = (projectId: string, groupId: number) => `/projects/${projectId}/groups/${groupId}/sources`;
const SEQUENCE_GROUP_PROGRESS = (projectId: string, groupId: number) => `/projects/${projectId}/groups/${groupId}/progress`;
const JOIN_SEQUENCE_GROPS = (projectId: string) => `/projects/${projectId}/groups/join`;

/* Fasta file */
const GET_STRUCTURE_PDB = (projectId: string, structureId: string) => `/projects/${projectId}/structure/pdb/${structureId}`;

/* Sequences */
// const GET_SEQUENCES = (projectId: string, groupId: number, filters: string = '') => `/projects/${projectId}/groups/${groupId}/all_sequences/metadata${filters ? `?filter=${filters}` : ''}`;
const GET_SEQUENCES = (projectId: string, groupId: number, queryParam: { filters?: string, limit?: string, sort?: string }) => {
  const link = `/projects/${projectId}/groups/${groupId}/sequences`;
  const newLink = queryParams(link, queryParam);

  return newLink;
};
const GET_SEQUENCE = (projectId: string, sequencesId: string) => `/projects/${projectId}/sequences/${sequencesId}`;

// const GET_SEQUENCES_EXTENDED_METADATA = (projectId: string, sequenceId: string) => `/projects/${projectId}/sequences/${sequenceId}/extendedMetadata`;
const GET_SEQUENCES_EXTENDED_METADATA = (projectId: string, sequenceId: string, type: string) => `/projects/${projectId}/sequences/${sequenceId}/${type}`;

// const SEQUENCE_TREE_URL = (groupId: number) => `/sequence-tree/${groupId}`;
const SEQUENCE_TREE_URL = (projectId: string, groupId: number) => `/projects/${projectId}/groups/${groupId}/tree`;
const SEQUENCES_FASTA_URL = (groupId: number, projectId: string, queryParam: { filters?: string, limit?: string, sort?: string }) => {
  const link = `/projects/${projectId}/groups/${groupId}/sequences/fasta`;
  const newLink = queryParams(link, queryParam);

  return newLink;
};
const SEQUENCE_FASTA = (projectId: string, sequenceId: string) => `/projects/${projectId}/sequences/${sequenceId}/fasta`;
/* Tags */
const SEQUENCES_TAGS = (projectId: string) => `/projects/${projectId}/tags`;
const TAG_LABELS = (projectId: string, tagId: string) => `/projects/${projectId}/tags/${tagId}/labels`;
const SET_SEQUENCE_TAG = (projectId: string, tagId: string, sequenceId: string) => `/projects/${projectId}/tags/${tagId}/sequences/${sequenceId}`;

const ADD_STRUCTURE_PREDICTION_URL = (sequenceId: string) => `/structure/sequence/${sequenceId}`;
/* Search */
const GET_LIST_GENETIC_SEARCHES = (projectId: string) => `/projects/${projectId}/searches`;
const GET_GENETIC_SEARCH = (projectId: string, searchId: string) => `/projects/${projectId}/searches/${searchId}`;
//
const SET_SEQUENCE_NAME_URL = (projectId: string, sequenceId: string) => `projects/${projectId}/sequences/${sequenceId}`;

/* Docking Ligands */
const LIGANDS = (projectId: string) => `/projects/${projectId}/ligands`;
const LIGANDS_SDF = (projectId: string, type: string, structureId: string, ligandId: string, poseId: number) => `/projects/${projectId}/docking/${type}/structure/${structureId}/ligand/${ligandId}/${poseId}.sdf`;
const DOCKED_PDB = (projectId: string, type: string, structureId: string, ligandId: string, poseId: number) => `/projects/${projectId}/docking/${type}/structure/${structureId}/ligand/${ligandId}/${poseId}.pdb`;
const DOCKING = (projectId: string, sequenceId: string) => `/projects/${projectId}/sequences/${sequenceId}/docking`;
const DOCKING_GROUP = (projectId: string, groupId: string) => `/projects/${projectId}/groups/${groupId}/docking`;
const DOCKING_PROGRESS = (projectId: string, sequenceId: string) => `/projects/${projectId}/sequences/${sequenceId}/docking/progress`;

/* Cluster */
// const CLUSTER_URL = (projectId: string, clusterId: string) => `/projects/${projectId}/cluster/${clusterId}`;
const CLUSTER_URL = (projectId: string, groupId: number, clusterId: string) => `/projects/${projectId}/groups/${groupId}/cluster/${clusterId}`;
const GET_SEQUENCE_CLUSTER = (projectId: string, groupId: number, clusterId: string, type: string, filters: string = '', sort = '') => {
  const link = `/projects/${projectId}/groups/${groupId}/cluster/${clusterId}/${type}`;
  const newLink = queryParams(link, { filters, sort });

  return newLink;
};
const SEQUENCES_MSA_URL = (groupId: number, projectId: string, queryParam: { filters?: string, limit?: string, sort?: string }) => {
  const link = `/projects/${projectId}/groups/${groupId}/msa`;
  const newLink = queryParams(link, queryParam);

  return newLink;
};

/* Location */
const LOCATIONS = (projectId: string, sequenceId: string) => `/projects/${projectId}/sequences/${sequenceId}/locations`;

/* Project Config */
export async function getProjectConfig({ projectId }: { projectId: string }): Promise<Config> {
  const result = await axios.get(GET_PROJECT_CONFIG(projectId));

  return result.data;
}

export async function setProjectConfig({ projectId, ...payload }: ConfigPayload) {
  const result = await axios.put(GET_PROJECT_CONFIG(projectId), payload.config);

  return result.data;
}
/**/
export async function getSequenceGroups({ projectId }: { projectId: string }): Promise<SequenceGroupData> {
  const result = await axios.get(SEQUENCE_GROUPS_URL(projectId));

  return result.data;
}

export async function getSequenceData({
  projectId,
  groupId,
  filters,
  limit,
  sort,
}: { projectId: string, groupId: number, filters?: SequenceFilter, limit?: string, sort?: string }): Promise<SequenceData> {
  const filterStringify = !_.isEmpty(filters) ? JSON.stringify(filters) : '';

  const result = await axios.get(GET_SEQUENCES(projectId, groupId, { filters: filterStringify, limit, sort }), { timeout: 200000 });

  return result.data;
}

export async function getSequence({
  projectId,
  sequencesId,
}: { projectId: string, sequencesId: string }): Promise<Sequence> {
  const result = await axios.get(GET_SEQUENCE(projectId, sequencesId), { timeout: 100000 });

  return result.data;
}

export async function getSequenceMetadata({
  projectId,
  sequenceId,
  type
}: { projectId: string, sequenceId: string, type: string }): Promise<SequenceData & Paper[] & SequenceSource[] & Structure[]> {
  const result = await axios.get(GET_SEQUENCES_EXTENDED_METADATA(projectId, sequenceId, type), { timeout: 100000 });

  return result.data;
}

export async function getSequenceTree({ projectId, groupId }: { projectId: string, groupId: number }): Promise<string> {
  const result = await axios.get<SequenceTree>(SEQUENCE_TREE_URL(projectId, groupId));

  return result.data?.tree;
}

export async function getSequencesFasta({ groupId, projectId, contentType, filters }: { groupId: number, projectId: string, contentType: string, filters?: SequenceFilter }): Promise<SequencesFasta> {
  const filterStringify = !_.isEmpty(filters) ? JSON.stringify(filters) : '';

  const result = await axios.get<SequencesFasta>(SEQUENCES_FASTA_URL(groupId, projectId, { filters: filterStringify }), {
    headers: {
      'Content-Type': contentType,
    },
    data: {},
  });

  return result.data;
}

export async function getSequencesMSA({ groupId, projectId, contentType, filters }: { groupId: number, projectId: string, contentType: string, filters?: SequenceFilter }): Promise<string> {
  const filterStringify = !_.isEmpty(filters) ? JSON.stringify(filters) : '';

  const result = await axios.get<string>(SEQUENCES_MSA_URL(groupId, projectId, { filters: filterStringify }), {
    headers: {
      'Content-Type': contentType,
    },
    data: {},
  });

  return result.data;
}

export async function checkSequencesMSA({ groupId, projectId, filters }: { groupId: number, projectId: string, filters?: SequenceFilter }): Promise<number> {
  const filterStringify = !_.isEmpty(filters) ? JSON.stringify(filters) : '';

  const result = await axios.head<number>(SEQUENCES_MSA_URL(groupId, projectId, { filters: filterStringify }));

  return result.status;
}

export async function getSequenceFasta({ projectId, sequenceId }: { projectId: string, sequenceId: string }): Promise<SequenceFasta> {
  const result = await axios.get<SequenceFasta>(SEQUENCE_FASTA(projectId, sequenceId));

  return result.data;
}

// Tags
export async function getProjectTags({ projectId }: { projectId: string }): Promise<Tag[]> {
  const result = await axios.get<Tag[]>(SEQUENCES_TAGS(projectId));

  return result.data;
}

export async function createTag({ projectId, ...payload }: CreateTagPayload) {
  const result = await axios.post<Tag>(SEQUENCES_TAGS(projectId), payload);

  return result.data;
}

export async function getTagLabels({ projectId, tagId }: GetTagPayload) {
  const result = await axios.get<SequenceTag[]>(TAG_LABELS(projectId, tagId));

  return result.data;
}

export async function addNewTagLabel({ projectId, tagId, ...payload }: CreateLabelPayload) {
  const result = await axios.post<SequenceTag[]>(TAG_LABELS(projectId, tagId), payload);

  return result.data;
}

export async function setSequenceTagValue({ projectId, tagId, sequenceId, ...payload }: UpdateTagSequencePayload) {
  const result = await axios.put<Tag>(SET_SEQUENCE_TAG(projectId, tagId, sequenceId), payload);

  return result.data;
}
//

export async function getSequenceGroupInfo({ projectId, groupId }: { projectId: string, groupId: number }): Promise<SequenceInfo> {
  const result = await axios.get<SequenceInfo>(SEQUENCE_GROUP_URL(projectId, groupId));

  return result.data;
}

export async function getStructurePdb({ projectId, structureId }: { projectId: string, structureId: string }): Promise<string> {
  const result = await axios.get<string>(GET_STRUCTURE_PDB(projectId, structureId));

  return result.data;
}

export async function getSequenceCluster({
  projectId,
  groupId,
  clusterId,
  type,
  filters,
  sort,
}: { projectId: string, groupId: number, clusterId: string, type: string, filters?: SequenceFilter, sort?: string }): Promise<Parents & Cluster[] & SequenceColumn[]> {
  const filterStringify = !_.isEmpty(filters) ? JSON.stringify(filters) : '';

  const result = await axios.get(GET_SEQUENCE_CLUSTER(projectId, groupId, clusterId, type, filterStringify, sort), { timeout: 100000 });

  return result.data;
}

export async function updateSequenceGroupInfo({ projectId, groupId, title, description }: {
  projectId: string;
  groupId: number;
  title?: string;
  description?: string;
}) {
  await axios.patch(SEQUENCE_GROUP_URL(projectId, groupId), { title, description });
}

export async function updateClusterInfo({ clusterId, title, projectId, groupId }: {
  clusterId: string;
  title?: string;
  projectId: string;
  groupId: number;
}) {
  await axios.patch(CLUSTER_URL(projectId, groupId, clusterId), { title });
}

export interface CreateSequenceGroupPayload {
  oldSequenceGroupId: number;
  title: string;
  description: string;
  sequences?: Array<string>;
  filter?: SequenceFilter;
}

export async function createSequenceGroup(payload: CreateSequenceGroupPayload) {
  const result = await axios.post<SequenceGroup>(SEQUENCE_GROUP_URL__DEPRECATED, payload);

  return result.data;
}

export interface JoinSequenceGroupsPayload {
  title: string;
  description: string;
  groupIds: number[];
  projectId: string;
}

export async function joinSequenceGroups(payload: JoinSequenceGroupsPayload) {
  const result = await axios.post<SequenceGroup>(JOIN_SEQUENCE_GROPS(payload.projectId), payload);

  return result.data;
}

export async function getUserProjects() {
  const result = await axios.get<Project[]>('/projects');

  return result.data;
}

interface CreateGroupFromFastaPayload {
  projectId: string;
  title: string;
  description: string;
  sequences: { sequence: string, name?: string, id: string }[];
}

export async function createSequenceGroupFromFasta({ projectId, ...payload }: CreateGroupFromFastaPayload) {
  const result = await axios.post<SequenceGroup>(SEQUENCE_GROUPS_FASTA_URL(projectId), payload);

  return result.data;
}

export async function createStructurePrediction(sequenceId: string) {
  await axios.post(ADD_STRUCTURE_PREDICTION_URL(sequenceId));
}

// Search
interface RunGeneticSearchPayload {
  projectId: string;
  searchType: string;
  querySequenceGroupId: number;
  referenceSequenceId: string;
  queryStructureId?: string;
  geneticCodeId?: number;
}

export async function runGeneticSearch({ projectId, ...payload }: RunGeneticSearchPayload) {
  const result = await axios.post(GET_LIST_GENETIC_SEARCHES(projectId), payload);

  return result.data;
}

export async function getListOfGeneticSearches({ projectId }: { projectId: string }) {
  const result = await axios.get(GET_LIST_GENETIC_SEARCHES(projectId));

  return result.data;
}

export async function getGeneticSearch({ projectId, searchId }: { projectId: string, searchId: string }) {
  const result = await axios.get(GET_GENETIC_SEARCH(projectId, searchId));

  return result.data;
}

export async function setSequenceName({ projectId, sequenceId, name }: {
  projectId: string;
  sequenceId: string;
  name: string;
}) {
  await axios.patch(SET_SEQUENCE_NAME_URL(projectId, sequenceId), { name });
}

// Delete Sequence Group
export async function deleteSequenceGroup({ projectId, groupId }: { projectId: string, groupId: number }) {
  const result = await axios.delete(SEQUENCE_GROUP_URL(projectId, groupId));

  return result.data;
}

export async function getSequenceGroupSource({ projectId, groupId }: { projectId: string, groupId: number }) {
  const result = await axios.get<(string | number)[]>(SEQUENCE_GROUP_SOURCE(projectId, groupId));

  return result.data;
}

export async function getSequenceGroupProgress({ projectId, groupId }: { projectId: string, groupId: number }) {
  const result = await axios.get<SequenceGroupProgress>(SEQUENCE_GROUP_PROGRESS(projectId, groupId));

  return result.data;
}

// Locations
export async function fetchSeqLocations({ projectId, sequenceId }: { projectId: string, sequenceId: string }): Promise<Location[]> {
  const result = await axios.get<Location[]>(LOCATIONS(projectId, sequenceId));

  return result.data;
}

export async function getProjectLigands({ projectId }: { projectId: string }): Promise<Ligand[]> {
  const result = await axios.get<Ligand[]>(LIGANDS(projectId));

  return result.data;
}

export async function putProjectLigands({ projectId, payload }: { projectId: string, payload: { smiles: string, name: string } }): Promise<Ligand> {
  const result = await axios.put<Ligand>(LIGANDS(projectId), payload);

  return result.data;
}

// Docking
export async function getSequenceDocking({ projectId, sequenceId }: { projectId: string, sequenceId: string }): Promise<LigandDocking[]> {
  const result = await axios.get<LigandDocking[]>(DOCKING(projectId, sequenceId));

  return result.data;
}

export async function putSequenceDocking({ projectId, sequenceId, payload }: { projectId: string, sequenceId: string, payload: { ligand_uuid: string, type: string } }) {
  await axios.put(DOCKING(projectId, sequenceId), payload);
}

export async function putGroupDocking({ projectId, groupId, payload }: { projectId: string, groupId: string, payload: { ligand_uuid: string, type: string } }) {
  await axios.put(DOCKING_GROUP(projectId, groupId), payload);
}

export async function getDockingProgress({ projectId, sequenceId }: { projectId: string, sequenceId: string }): Promise<LigandDockingStatus[]> {
  const result = await axios.get<LigandDockingStatus[]>(DOCKING_PROGRESS(projectId, sequenceId));

  return result.data;
}

export async function getLigandSDFfile({ projectId, type, structureId, ligandId, poseId }: { projectId: string, type: string, structureId: string, ligandId: string, poseId: number }): Promise<string> {
  const result = await axios.get<string>(LIGANDS_SDF(projectId, type, structureId, ligandId, poseId));

  return result.data;
}

export async function getDockedPDBfile({ projectId, type, structureId, ligandId, poseId }: { projectId: string, type: string, structureId: string, ligandId: string, poseId: number }): Promise<string> {
  const result = await axios.get<string>(DOCKED_PDB(projectId, type, structureId, ligandId, poseId));

  return result.data;
}
