import { Suspense, useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import axiosRetry from 'axios-retry';

import { Loader } from 'components/Loader';
import { AuthProvider } from 'common/AuthProvider';
import { ProtectedRoute } from 'common/ProtectedRoute';

import { axios } from 'api';

import {
  EmptyPage,
  ErrorPage,
  Login,

  // Lazy loaded
  Projects,
  SequenceView,
  GroupView,
  Groups,
  ClusterView,
  StructureView,
  CompareView,
  MSAView,
} from 'containers';
// Context
import { ConfigContextProvider } from './contexts/ConfigContext';

function App(): JSX.Element {
  const navigate = useNavigate();
  const [isConnection, setConnection] = useState(true);
  const [retryCount, setRetryCount] = useState(0);

  const navigateToLoginPage = () => {
    navigate('/login', {
      replace: true,
      state: { from: undefined, showUpdatePasswordForm: false },
    });
  };

  useEffect(() => {
    document.title = 'Proteineer Gene Store';

    axiosRetry(axios, {
      retries: 10,
      retryDelay: (retryCount: number) => {
        setRetryCount(retryCount);
        setConnection(false);

        return retryCount * 5000; // time interval between retries
      },
      retryCondition: (error: any) => {
        // Redirect to lign if user unauthorized
        if (error?.response?.status === 401) navigateToLoginPage();

        // if retry condition is not specified, by default idempotent requests are retried
        return error?.response?.status === 500;
      },
    });
  }, []);

  return (
    <AuthProvider>
      <Suspense fallback={<Loader/>}>
        <ConfigContextProvider>
          <Routes>
            <Route path="/login" element={<Login />} />
            {isConnection
              ? <Route element={<ProtectedRoute />}>
                  <Route path="/" index element={<Projects />} />
                  <Route path="/projects/:projectId/groups" element={<Groups />} />
                  <Route path="/projects/:projectId/groups/:groupId" element={<GroupView />} />
                  <Route path="/projects/:projectId/groups/:groupId/sequences" element={<SequenceView />} />
                  <Route path="/projects/:projectId/groups/:groupId/sequences/msa" element={<MSAView />} />
                  <Route path="/projects/:projectId/groups/:groupId/clusters/:clusterId/msa" element={<MSAView />} />
                  <Route path="/projects/:projectId/groups/:groupId/sequences/structure" element={<StructureView />} />
                  <Route path="/projects/:projectId/groups/:groupId/compare" element={<CompareView />} />
                  <Route path="/projects/:projectId/groups/:groupId/sequences/compare" element={<CompareView />} />
                  <Route path="/projects/:projectId/groups/:groupId/clusters/:clusterId" element={<ClusterView />} />
                  <Route path="/projects/:projectId/groups/:groupId/clusters/:clusterId/compare" element={<CompareView />} />
                  <Route path="*" index element={<EmptyPage />} />
                </Route>
            : <Route path="*" index element={<ErrorPage setConnection={setConnection} retryCount={retryCount} />} />
            }
          </Routes>
        </ConfigContextProvider>
      </Suspense>
    </AuthProvider>
  );
}

export default App;
