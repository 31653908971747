import { createContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

// API
import { sequenceApi } from 'api';
// Types
import { Config, Ligand, Project, SequenceTag, Tag } from 'types';
import _ from 'lodash';
import { ColumnFilter } from 'components/SequenceTable/types';

interface ConfigContextType {
  config: Config;
  ligands: Ligand[];
  refreshLigands: () => void;
  tags: Tag[];
  tagLabels: Record<string, SequenceTag[]>;
  refreshTags: () => void;
  sequenceGroupSource: (string | number)[];
  filter: Record<string, ColumnFilter>;
  setFilter: (filter: Record<string, ColumnFilter>) => void;
  projects: Project[];
}

const ConfigContext = createContext<ConfigContextType>({
  config: {},
  ligands: [],
  refreshLigands: () => {},
  tags: [],
  tagLabels: {},
  refreshTags: () => {},
  sequenceGroupSource: [],
  filter: {},
  setFilter: () => {},
  projects: [],
});

const ConfigContextProvider = ({ children }: any) => {
  const location = useLocation();

  /*
   * projectId
   */
  const projectId = useMemo(() => {
    // This effect will run whenever the URL changes
    const urlSegments = location.pathname.split('/');
    // Find the index of the 'projects' segment
    const projectsIndex = urlSegments.indexOf('projects');
    // Extract the ID following the 'projects' segment
    return urlSegments[projectsIndex + 1];
  }, [location.pathname]);

  /*
   * config
   */
  const [config, setConfig] = useState<Config>({});
  const [projects, setProjects] = useState<Project[]>([]);

  const fetchProjectConfig = () => {
    if (!projectId) {
      setConfig({});
      return;
    }
    sequenceApi.getProjectConfig({ projectId })
      .then(data => {
        setConfig(data);
      })
      .catch(error => {
        setConfig({});
        console.error('Get project config error:', error);
      });
  };

  const fetchListOfProjects = () => {
    sequenceApi.getUserProjects()
      .then((projects) => {
        setProjects(projects);
      })
      .catch((error) => {
        console.error('Get user projects failed:', error);
      });
  };

  useEffect(() => {
    fetchProjectConfig();
  }, [projectId]);

  useEffect(() => {
    fetchListOfProjects();
  }, []);
  /*
   * ligands
   */
  const [ligands, setLigands] = useState<Ligand[]>([]);

  const refreshLigands = () => {
    if (!projectId || !config.frontendConfig?.ligandDocking) {
      setLigands([]);
      return;
    }
    sequenceApi.getProjectLigands({ projectId })
      .then(data => {
        setLigands(data);
      })
      .catch(error => console.error('Get project ligends error:', error));
  };

  useEffect(() => {
    refreshLigands();
  }, [projectId, config]);

  /*
   * tags
   */
  const [tags, setTags] = useState<Tag[]>([]);
  const [tagLabels, setTagLabels] = useState<Record<string, SequenceTag[]>>({});

  const refreshTags = () => {
    if (projectId === undefined || !config.frontendConfig?.tags) {
      setTags([]);
      setTagLabels({});
      return;
    }
    sequenceApi.getProjectTags({ projectId })
      .then(projectTags => {
        setTags(projectTags);
      })
      .catch(() => {});
  };

  useEffect(() => {
    refreshTags();
  }, [projectId, config]);

  useEffect(() => {
    // TODO: Remove all tagLabels that have no tag.
    if (!projectId || !config.frontendConfig?.tags) {
      setTagLabels({});
      return;
    }
    _.keys(tagLabels).forEach(tagId => {
      if (!tags.find(tag => tag.uuid === tagId)) {
        setTagLabels(prevState => _.omit(prevState, tagId));
      }
    });
    tags.forEach(tag => {
      sequenceApi.getTagLabels({ projectId, tagId: tag.uuid }).then(data => {
        setTagLabels(prevState => ({ ...prevState, [tag.uuid]: data }));
      }).catch(() => {
        setTagLabels(prevState => ({ ...prevState, [tag.uuid]: [] }));
      });
    });
  }, [projectId, config, tags]);

  /*
   * groupId
   */
  const groupId = useMemo(() => {
    const urlSegments = location.pathname.split('/');
    const groupsIndex = urlSegments.indexOf('groups');
    if (groupsIndex === -1 || groupsIndex === urlSegments.length - 1) {
      return undefined;
    }
    const n = parseInt(urlSegments[groupsIndex + 1]);
    return isNaN(n) ? undefined : n;
  }, [location.pathname]);

  /*
   * sequenceGroupSource
   */
  const [sequenceGroupSource, setSequenceGroupSource] = useState<(string | number)[]>([]);

  useEffect(() => {
    if (!projectId || !groupId) {
      setSequenceGroupSource([]);
      return;
    }
    sequenceApi.getSequenceGroupSource({ projectId, groupId })
      .then(data => {
        setSequenceGroupSource(data);
      })
      .catch(error => {
        console.error('Get group sequences error:', error);
      });
  }, [projectId, groupId]);

  /*
   * filter
   */
  const [filter, setFilter] = useState<Record<string, ColumnFilter>>({});

  useEffect(() => {
    // Reset filter on groupId change.
    setFilter({});
  }, [groupId]);

  return (
    <ConfigContext.Provider value={{ config, ligands, refreshLigands, tags, tagLabels, refreshTags, sequenceGroupSource, filter, setFilter, projects }}>
      {children}
    </ConfigContext.Provider>
  );
};

export { ConfigContext, ConfigContextProvider };
